export const colors = {
  white: '#FFF', // --BW-White
  black: '#2C3957', // --BW-Black
  black700: '#556282', // --BW-Black-700
  black500: '#9BA8B7', // --BW-Black-500
  black400: '#D0D6DD', // --BW-Black-400
  black300: '#E6E9ED', // --BW-Black-300
  black100: '#F1F3F9', // --BW-Black-100
  blue: '#6A87FF', // --Main-Blue
  blue50: '#5770D9', // --Main-Blue-50
  blue40: '#8AA1FF', // --Main-Blue-40
  blue30: '#C9D3FF', // --main-blue-30
  blue10: '#F1F3FF', // --main-blue-10
  steel: '#B5C1DA', // --Main-Steel
  azureish: '#DAE6EF', // --Main-Azureish
  peach: '#F8CD91', // --Accent-Peach
  teal: '#397D8C', // --Accent-Teal
  red: '#DE4355', // --Accent-Red
  grey700: '#344054', // Gray/700
};
