'use client';
import React from 'react';
import { useRouter } from 'next/navigation';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import BaseStretchedContainer from '@/shared/components/BaseStretchedContainer';
import ButtonComponent from '@/shared/components/ButtonComponent';
import Icon from '@/shared/components/Icon';
import { colors } from '@/lib/theme/palette';

export default function Error({
  error,
}: {
  error: Error & { digest?: string };
}) {
  const { push } = useRouter();
  console.log(error, 'error');
  return (
    <main>
      <BaseStretchedContainer
        sx={{
          padding: {
            xs: '74px 24px 50px',
            sm: '48px 7% 100px',
            md: '48px 7% 100px',
            lg: '48px 8% 100px',
            xl: '80px 8% 100px',
          },
        }}
      >
        <Container disableGutters>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="h1" component="h1">
              Oops, there&apos;s something wrong!
            </Typography>
            {/*<Typography*/}
            {/*  variant="h1"*/}
            {/*  component="h1"*/}
            {/*  sx={{*/}
            {/*    marginTop: {*/}
            {/*      xs: '40px',*/}
            {/*      sm: '32px',*/}
            {/*    },*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {error.message}*/}
            {/*</Typography>*/}
            <ButtonComponent
              variant="outlined-primary"
              sx={{
                maxWidth: {
                  xs: '141px',
                  sm: '169px',
                  md: '182px',
                },
                padding: 0,
                alignItems: 'center',
                '& .MuiBox-root': {
                  marginRight: 0,
                },
                marginTop: {
                  xs: '24px',
                  sm: '48px',
                  xl: '56px',
                },
              }}
              prepend={
                <Icon
                  width={{ xs: 21 }}
                  height={{ xs: 21 }}
                  color={colors.blue}
                  icon="home"
                />
              }
              onClick={() => push('/')}
            >
              Back to home
            </ButtonComponent>
          </Box>
        </Container>
      </BaseStretchedContainer>
    </main>
  );
}
