'use client';
import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { colors } from '@/lib/theme/palette';
import { PropsButton } from './buttons.type';

const ButtonOutlinedSecondary = (props: PropsButton) => {
  const {
    prepend,
    append,
    width,
    height,
    children,
    disabled,
    type,
    sx,
    loading,
    loadingStyle,
    href,
    className,
    component = 'button',
    onClick,
  } = props;

  return (
    <Typography
      component={href ? 'a' : component}
      variant="button"
      width="100%"
      height={height || { xs: '40px', sm: '60px' }}
      textTransform="none"
      disabled={disabled}
      display="flex"
      justifyContent="center"
      alignItems="center"
      type={type}
      href={href}
      sx={{
        bosxSizing: 'border-box',
        cursor: 'pointer',
        maxWidth: width || '100%',
        backgroundColor: 'transparent',
        color: colors.black,
        border: `1px solid ${colors.black300}`,
        position: 'relative',
        borderRadius: { xs: 1, sm: 2 },
        paddingX: 2,
        fontWeight: 500,
        transition:
          'background-color 0.2s ease-in-out, color 0.2s ease-in-out, border 0.2s ease-in-out',

        ...(!loading
          ? {
              '& *': {
                color: colors.steel,
                fill: colors.steel,
              },
              '&:hover': {
                borderColor: colors.blue,

                '& *': {
                  color: colors.blue,
                  fill: colors.blue,
                },
              },
            }
          : {}),

        '&:active': {
          borderColor: colors.blue50,

          '& *': {
            color: colors.blue50,
            fill: colors.blue50,
          },
        },

        '&:disabled': {
          borderColor: colors.black400,
          pointerEvents: 'none',
          color: colors.black500,

          '& > *': {
            color: colors.black500,
            fill: colors.black500,
          },
        },
        ...sx,
      }}
      className={className}
      onClick={onClick}
    >
      {loading ? (
        <CircularProgress
          size={loadingStyle?.size || 24}
          sx={{
            color: `${colors.blue} !important`,
            position: 'absolute',
            top: `calc(50% - ${loadingStyle?.size / 2 || 12}px)`,
            left: `calc(50% - ${loadingStyle?.size / 2 || 12}px)`,
          }}
        />
      ) : null}

      {prepend && (
        <Box
          component="span"
          display="flex"
          alignItems="center"
          marginRight={1}
        >
          {prepend}
        </Box>
      )}

      {children}

      {append && (
        <Box component="span" display="flex" alignItems="center" marginLeft={1}>
          {append}
        </Box>
      )}
    </Typography>
  );
};

export default ButtonOutlinedSecondary;
