import React from 'react';
import Container from '@mui/material/Container';
import { SxProps } from '@mui/material';

interface BaseStretchedContainerProps {
  children?: React.ReactNode;
  sx?: SxProps;
}

export default function BaseStretchedContainer(
  props: BaseStretchedContainerProps,
) {
  const { children, sx } = props;
  return (
    <Container
      maxWidth={false}
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: {
          xs: '50px 24px',
          sm: '54px 7%',
          md: '57px 7%',
          lg: '60px 8%',
          xl: '100px 8%',
        },
        ...sx,
      }}
    >
      {children}
    </Container>
  );
}
